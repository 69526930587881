//to compile node_modules/.bin/webpack 
import * as sbjs from 'sourcebuster';
import * as URI from "urijs";
import 'whatwg-fetch';
import 'navigator.sendbeacon';
import 'url-search-params-polyfill';
//import * as jq from "jquery";
import * as Cookies from "js-cookie";
interface FormDataParams {
    [key: string]: string;
}

interface RrpCaptureParams {
    disposition: string,
    type: string,
    subject: string,
}

declare var rrp_settings: {
    url: string,
    key: string,
    chat_url: RequestInfo,
    chat_api_urls: Array<RequestInfo>,
    fetch_campaigns_url: RequestInfo,
}

interface IFrameXMLHttpRequest extends XMLHttpRequest {
    prototype: {
        open: Function,
        readystatechange: Function
    }
}

declare global {
    interface Window {
        rrpCapture: Function;
        XMLHttpRequest: IFrameXMLHttpRequest;
        jQuery: any;
        $: any;
    }
}
//Turn on sourcebuster
sbjs.init();

async function postJsonData(url: string, data: object) {
    /*const form_data = new FormData();
    Object.keys(data).forEach(key => form_data.append(key, data[key]));*/
    let res = await (
        await (
            fetch(
                url,
                {
                    method: "POST", // *GET, POST, PUT, DELETE, etc.
                    mode: "cors", // no-cors, cors, *same-origin
                    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: "same-origin", // include, *same-origin, omit
                    headers: {
                        "Content-Type": "application/json",
                    },
                    redirect: "follow", // manual, *follow, error
                    referrer: "no-referrer", // no-referrer, *client
                    body: JSON.stringify(data), // body data type must match "Content-Type" header
                }
            ).then(res => {
                return res.json()
            }).catch(err => {
                console.log('Error: ', err)
            })
        ))
    return res;
}

/*
Disposition: (lead or nolead)
Type: (form, mailto, chat, offsite, etc.)
Detail: (free text, whatever max characters you think appropriate, optional)
*/
window.rrpCapture = (
    rrpCaptureParams: RrpCaptureParams,
): boolean => {
    //event.preventDefault();
    if (!rrp_settings || typeof rrp_settings !== 'object') {
        throw new Error("rrp_settings must be defined");
    }
    if (!("url" in rrp_settings)) {
        throw new Error("rrp_settings.url must be defined");
    }
    if (!("key" in rrp_settings)) {
        throw new Error("rrp_settings.key must be defined");
    }
    var data = {
        'cookies': Cookies.get(),
        'values': rrpCaptureParams
    }
    var url = URI(`${rrp_settings.url}/api/${rrp_settings.key}/capture`.replace("//", "/"));
    navigator.sendBeacon(
        url.toString(),
        JSON.stringify(data)
    );
    return true;
};
function waitForElement(selector: string) {
    return new Promise(function (resolve, reject) {
        var element = document.querySelector(selector);

        if (element) {
            resolve(element);
            return;
        }

        var observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                if (mutation.type === 'attributes') {
                    var element = document.querySelector(selector);

                    if (element) {
                        observer.disconnect();
                        resolve(element);
                        return;
                    }
                }
                [].forEach.call(mutation.addedNodes, function (node: Element) {
                    if (node.matches && node.matches(selector)) {
                        observer.disconnect();
                        resolve(node);
                        return;
                    }
                });
            });
        });

        observer.observe(
            document.documentElement,
            {
                childList: true,
                subtree: true,
                attributes: true,
            }
        );
    });
}

waitForElement("[data-chat-id]").then(function (element: HTMLIFrameElement) {
    var cookies: FormDataParams = {};
    Object.keys(Cookies.get()).filter(k => k.match(/^sbjs/i)).forEach(element => {
        cookies[element] = Cookies.get(element);
    });
    Promise.all(
        rrp_settings.chat_api_urls.map(
            u => postJsonData(
                u.toString(),
                {
                    'chat_id': element.getAttribute('data-chat-id'),
                    'json': JSON.stringify(
                        {
                            'cookies': cookies
                        }
                    )
                }
            )
        )
    ).then(
        responses =>
            Promise.all(
                responses.map(res => res)
            )
    )
    /*
    //make sure scheme matches
    var uri = new URI();
    var post_uri = new URI(rrp_settings.chat_url.toString());
    post_uri.protocol(uri.protocol());
        postJsonData(
            post_uri.toString(),
            {
                'chat_id': element.getAttribute('data-chat-id')
            }
        )*/
});

document.addEventListener("DOMContentLoaded", function () {
    var url_params = new URLSearchParams(window.location.search);
    var url = URI(rrp_settings.fetch_campaigns_url.toString());
    if (url_params.has('rrpclear')) {
        url.addQuery(
            "rrpclear",
            1
        );
    }

    fetch(
        url.toString() as RequestInfo,
        {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
        }
    ).then(res => {
        return res.json()
    }).catch(err => {
        console.log('Error: ', err)
    })

});
